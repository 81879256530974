<template>
<div>
  <div class="users scrollable-x mb-3">
    <div class="d-flex">
      <h1 style="display: inline;" class="mr-4">Users</h1>
      <div class="align-self-center">
        <select @change="updateUserFilter">
          <option value="recent">Recent</option>
          <option value="unarchived">Unarchived</option>
          <option value="onlineonly">Online Only</option>
          <option value="archived">Archived</option>
          <option value="test">Test Users</option>
          <option value="moderators">Moderators</option>
          <option value="all">All</option>
        </select>
      </div>
      <div class="ml-3 align-self-center" v-if="$debug.isDeveloperOn">
        <a target="_blank" 
          :href="'/tests/multi-guests?users=' + listFilteredUsers">
          Open guests in MultiGuests page🔗
        </a>
      </div>
    </div>
    <div class="d-flex" v-if="filteredUsers.length > 0">
      <div class="user" v-for="user in filteredUsers" v-bind:key="user.id">
        <user-view class="mr-1"
          v-bind:user="user"
          v-bind:eventUser="eventUsers[user.id]"
        />
        <div class="d-flex flex-column mt-1 mb-2" v-if="manager">
          <div class="form-inline">
            <b-form-checkbox :checked="user.canCreateEvents" @change="(value) => updateUser(user.id, {canCreateEvents:value})">
            Can Create Events
            </b-form-checkbox>
          </div>
          <button class="btn btn-warning m-1"
            v-for="eid in manager.activeEvents" v-bind:key="eid"
            @click="addUsertoEventId(user.id, eid)">
            Add to event: {{ eid }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <p>No users online</p>
    </div>
  </div>
</div>
</template>

<script>
import { db } from '@/services/db';
import user from "./User";
import { addUsertoEventId, updateUser } from "@/services/user";
import { getLog } from "@/services/log";
import { bindMapFromArray } from "@/services/utils";
import { epoch } from '@/services/utils';
let log = getLog('admin-users');

export default {
  name: 'admin-users',
  components: {
    userView: user,
  },
  props: {
    manager: Object,
  },
  data() {
    return {
      users: [],
      usersMap: {},
      usersFilter: "recent",
      filteredUsers: [],
      eventUsers: {},
      eventUsers_asArray: [],
      roomMatches: {},
    };
  },
  mounted() {
    log.log("mounted()");

    bindMapFromArray(this, "usersMap", "users");
    this.$bind("users", db.collection("LiveUsers").orderBy("createdAt")).then((users) => {
      log.log(`Found ${users.length} user`);
      this.updateUserFilter();
    })
  },
  computed: {
    participatingUsers() {
      return this.users.filter(u => !u.archived && !u.moderator);
    },
    eventModerators() {
      return this.users.filter(u => u.moderator);
    },
    listFilteredUsers() {
      return JSON.stringify(this.filteredUsers.map((u) => { return {id: u.id, name: u.name}; }));
    },
    testUsersOnly() {
      return [];
    }
  },
  watch: {
    users() {
      if (!this.users)
        return;
      this.updateUserFilter();
    },
  },
  methods: {
    addUsertoEventId,
    updateUser,
    updateUserFilter(event) {
      let filter = event ? event.target.value : this.usersFilter;
      log.log("updateUserFilter", filter);
      let yesterday = epoch() - 24 * 3600;
      this.usersFilter = filter;
      if (filter == "unarchived")
        this.filteredUsers = this.users.filter(u => !u.archived);
      else if (filter == "onlineonly")
        this.filteredUsers = this.users.filter(u => u.online);
      else if (filter == "archived")
        this.filteredUsers = this.users.filter(u => u.archived);
      else if (filter == "moderators")
        this.filteredUsers = this.users.filter(u => u.moderator);
      else if (filter == "test")
        this.filteredUsers = this.users.filter(u => u.test);
      else if (filter == "recent")
        this.filteredUsers = this.users.filter(u => u.createdAt.seconds > yesterday);
      else
        this.filteredUsers = this.users.filter(u => !u.archived && !u.moderator);
    },
  }
};
</script>

<style scoped>

.scrollable-x {
  overflow: auto;
  overflow-x: scroll;
  width: calc(100vw - 200px);
}

</style>